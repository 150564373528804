import React, { useState, useEffect } from 'react';
import './Cronometro.css';
import { BarLoader } from 'react-spinners';

const ContagemRegressiva = () => {
  const [tempoRestante, setTempoRestante] = useState(null);

  const formatNumber = (number) => {
    return number < 10 ? `0${number}` : `${number}`;
  };

  const calcularTempoRestante = () => {
    const dataAlvo = new Date('2025-01-01T00:00:00Z');

    const diferenca = dataAlvo - new Date();
    const segundos = Math.floor((diferenca / 1000) % 60);
    const minutos = Math.floor((diferenca / 1000 / 60) % 60);
    const horas = Math.floor((diferenca / (1000 * 60 * 60)) % 24);
    const dias = Math.floor(diferenca / (1000 * 60 * 60 * 24));

    setTempoRestante({
      dias: formatNumber(dias),
      horas: formatNumber(horas),
      minutos: formatNumber(minutos),
      segundos: formatNumber(segundos)
    });
  };

  useEffect(() => {
    const espera = setTimeout(() => {
      calcularTempoRestante();
    }, 2500);

    return () => clearTimeout(espera);
  }, );

  useEffect(() => {
    const intervalId = setInterval(() => {
      calcularTempoRestante();
    }, 1000);

    return () => clearInterval(intervalId);
  }, );

  return (
        <div className="contagem-regressiva-container">
          <h1 className="contagem-regressiva-title">
            Contagem Regressiva para 2025
          </h1>
          {tempoRestante ? (
            <div className="contagem-regressiva-timer">
              {Object.keys(tempoRestante).map((key, index) => (
                <div key={index} className="tempo-item">
                  <p className="tempo-numero">{tempoRestante[key]}</p>
                  <p className="tempo-label">{key.toUpperCase()}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="loading-indicator">
              <BarLoader
                color="#63d3e7"
                cssOverride={{}}
                height={5}
                loading
                speedMultiplier={1}
                width={200}
              />
              <p className="calc">Calculando contagem regressiva...</p>
            </div>
          )}
        </div>
      );
    };

export default ContagemRegressiva;
