import Automatica from "./components/Automatica.jsx";


function App() {
  return (
    <>
    <Automatica />
    
    </>
  );
}

export default App;
