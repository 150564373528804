/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import "./stylewhats.css";
import Feliznatal from "../img/feliznatal.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cronometro from "./cronometro/Cronometro";

export default function MsgWhats() {
  const [nome, setNome] = useState("");
  const [nomeEmbarcacao, setNomeEmbarcacao] = useState("");
  const [valor, setValor] = useState("");
  const [errorNome, setErrorNome] = useState("");
  const [errorEmbarcacao, setErrorNomeEmbarcacao] = useState("");
  const [errorValor, setErrorValor] = useState("");
  const notify = () => {
    toast.warn("Verifique os campos, minimo de 3 caracteres!", {
      position: "top-center",
      autoClose: 5001,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const formatarNumero = (input) => {
    // Remover todos os caracteres não numéricos
    const numeroLimpo = input.replace(/[\D]+/g, "");

    // Converter para número e formatar
    const numeroFormatado = parseFloat(numeroLimpo).toLocaleString("pt-BR", {});

    return `R$${numeroFormatado}`;
  };

  const handleChange = (e) => {
    const valorDigitado = e.target.value;
    const valorFormatado = formatarNumero(valorDigitado);
    setValor(valorFormatado);
  };

  const handleSearch = () => {
    let isNomeValid = true;
    let isNomeEmbarcacaoValid = true;
    let isValorValid = true;

    if (nome.length <= 3) {
      setErrorNome("form-control error");
      notify();
      isNomeValid = false;
    } else {
      setErrorNome("form-control success");
    }

    if (nomeEmbarcacao.length <= 3) {
      setErrorNomeEmbarcacao(`form-control error`);
      notify();
      isNomeEmbarcacaoValid = false;
    } else {
      setErrorNomeEmbarcacao("form-control success");
    }

    if (valor.trim() === "") {
      setErrorValor("form-control error");
      isValorValid = false;
    } else {
      setErrorValor("form-control success");
    }

    if (isNomeValid && isNomeEmbarcacaoValid && isValorValid) {
      const mensagem = `Olá, meu nome é ${nome}. Nome da embarcação: ${nomeEmbarcacao}. Valor: ${valor} ;)`;
      window.location.href = `https://wa.me/5513997370035?text=${encodeURIComponent(
        mensagem
      )}`;
    } else {
      console.error("error");
    }

    // Restante da sua lógica, se necessário...
  };

  return (
    <div className="fundo">
      <div className="container">
        <img src={Feliznatal} />
        <br/>
        <h2>
          <p>
            Que as realizações alcançadas esse ano, sejam apenas sementes
            plantadas que vão ser colhidas com maior sucesso no ano que está por
            vir. Feliz Natal e um próspero Ano Novo!!
          </p>

          <Cronometro />
        </h2>
        <div className={errorNome}>
          <p className="paradescricao">Nome</p>
          <input
            className="pesquisa"
            type="text"
            placeholder=""
            id="username"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <small className="small-error">Complete o campo</small>
        </div>
        <div className={errorEmbarcacao}>
          <p className="paradescricao">Nome da embarcação</p>
          <input
            className="pesquisa2"
            type="text"
            placeholder=""
            value={nomeEmbarcacao}
            onChange={(e) => setNomeEmbarcacao(e.target.value)}
          />
          <small className="small-error">Complete o campo</small>
        </div>
        <div className={errorValor}>
          <p className="paradescricao">Valor</p>
          <input
            className="pesquisa3"
            type="text"
            placeholder=""
            value={valor}
            onChange={handleChange}
          />
          <small className="small-error">Complete o campo</small>
        </div>
        <button onClick={handleSearch}>
          <BsFillRocketTakeoffFill />
        </button>

        <h3>Funcionários da Marina Hitio Agradece!!</h3>
        <h3 className="ultimalinha">Caixinha dos Garagistas!</h3>
      </div>
      <div>
        <ToastContainer />
      </div>
    </div>
  );
}
